import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="page-not-found">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-center">PAGE NOT FOUND</h1>
            <p className="mt-5 text-center">
              We looked everywhere for this page. Are you sure the website URL
              is correct? Get in touch with the site owner.
            </p>
            <div className="text-center mt-3">
              <Link to="/" className="btn-global hvr-icon-forward">
                GO BACK HOME
                <i className="fa fa-chevron-right ml-2 hvr-icon"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
